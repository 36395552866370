var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sign-content" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "tool-bar" },
      [
        _c(
          "el-button",
          {
            staticClass: "txBlue",
            attrs: { type: "text" },
            on: {
              click: function ($event) {
                return _vm.reset()
              },
            },
          },
          [_c("i", { staticClass: "el-icon-refresh-left" }), _vm._v(" 重签")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "drawing-board" }, [
      _c("canvas", { staticClass: "canvas", attrs: { id: "canvas" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }