<template>
	<div class="sign-content" >
		<div class="drawing-board">
			<canvas id="canvas" class="canvas"></canvas>
		</div>
		<div class="tool-bar">
			<el-button @click="reset()"  type="text" class='txBlue'><i class="el-icon-refresh-left"></i> 重签</el-button>
			<!--<el-button @click="httpSignResource()" size="small" type="success">确定</el-button>-->
		</div>
	</div>
</template>
<script>
	const $ = name => document.querySelector(name)
	const mobileStatus = (/Mobile|Android|iPhone/i.test(navigator.userAgent)) // 判断是否为移动端
	// 配置内容
	const config = {
		width: 0, // 宽度
		height: 80, // 高度
		lineWidth: 1, // 线宽
		strokeStyle: '#4E5969', // 线条颜色
		lineCap: 'round', // 设置线条两端圆角
		lineJoin: 'round', // 线条交汇处圆角
	}
	// 偏移量
	const client = {
		offsetX: 0,
		offsetY: 0
	}
	let canvas, ctx;
	export default {
		props: {
			gapX:{
				//某些场景下可能有偏差
				type:Number,
				default:0,
			},
			gapY:{
				type:Number,
				default:0,
			},
			
		},
		data(){
			return{
				isNull:true,
			}
			
		},
		mounted() {
			console.log('22222221isNull',this.isNull)
			this.$nextTick(()=>{
				this.drawingBoardInit();
			},1000)
		},
		destroyed() {
			window.removeEventListener(mobileStatus ? "touchstart" : "mousedown", this.init)
			window.removeEventListener(mobileStatus ? "touchend" : "mouseup", this.cloaseDraw)
//			window.removeEventListener('resize', this.resetSize)
		},
		methods: {
			drawingBoardInit() {
				const {
					width,
					height,
					left,
					top
				} = $('.drawing-board').getBoundingClientRect()
				config.width = width
				config.height = height
				client.offsetX = left
				client.offsetY = top
				// canvas 实例
				canvas = $('#canvas')
				// 设置宽高
				canvas.width = config.width
				canvas.height = config.height
				// 设置边框
//				canvas.style.border = '1px solid #DCDFE6'
				// 创建上下文
				ctx = canvas.getContext('2d')
				// 设置填充背景色
				ctx.fillStyle = 'transparent'
				// 绘制填充矩形
				ctx.fillRect(
					0, // x 轴起始绘制位置
					0, // y 轴起始绘制位置
					config.width, // 宽度
					config.height // 高度
				);
				this.resetSize();
				// 创建鼠标/手势按下监听器
				window.addEventListener(mobileStatus ? "touchstart" : "mousedown", this.init)
				// 创建鼠标/手势 弹起/离开 监听器
				window.addEventListener(mobileStatus ? "touchend" : "mouseup", this.cloaseDraw)
				// 自适应画布
				this.resetSize();
//				window.addEventListener('resize', this.resetSize)
			},
			// 鼠标按下
			init(event) {
				// 获取偏移量及坐标
				const {
					clientX,
					clientY
				} = mobileStatus ? event.changedTouches[0] : event
				// 清除以上一次 beginPath 之后的所有路径，进行绘制
				ctx.beginPath()
				// 根据配置文件设置相应配置
				ctx.lineWidth = config.lineWidth
				ctx.strokeStyle = config.strokeStyle
				ctx.lineCap = config.lineCap
				ctx.lineJoin = config.lineJoin
				// 设置画线起始点位（减去 左边、上方的偏移量很关键）
				ctx.moveTo(clientX - client.offsetX-this.gapX, clientY - client.offsetY-this.gapY)
				// 监听 鼠标移动或手势移动
				window.addEventListener(mobileStatus ? "touchmove" : "mousemove", this.draw)
			},
			// 绘制
			draw(event) {
				// 获取当前坐标点位
				const {
					clientX,
					clientY
				} = mobileStatus ? event.changedTouches[0] : event
				// 根据坐标点位移动添加线条（减去 左边、上方的偏移量很关键）
				
				ctx.lineTo(clientX - client.offsetX-this.gapX, clientY - client.offsetY-this.gapY)
				// 绘制
				ctx.stroke()
				if(this.isNull){
					//证明画了 有数据
					let x1=client.offsetX,x2=client.offsetX+config.width;
					let y1=client.offsetY,y2=client.offsetY+config.height;
					if(x1<clientX&&clientX<x2&&y1<clientY&&clientY<y2){
						this.isNull=false;
					}
				}
				
				
			},
			// 结束绘制
			cloaseDraw() {
				// 结束绘制
				ctx.closePath()
				// 移除鼠标移动或手势移动监听器
				window.removeEventListener("mousemove", this.draw)
			},
			// 清除
			reset() {
				this.isNull=true;
				// 清空当前画布上的所有绘制内容
				ctx.clearRect(0, 0, config.width, config.height)
			},
			getSignResource() {
				// 将画布内容转为图片后传给后台
				return new Promise((resolve, reject) => {
					if(this.isNull){
						resolve(false);
					}else{
						let imgBase64 = canvas.toDataURL('png')
						canvas.toBlob(blob => {
							let formData=new FormData();
							formData.append('file',blob);
							this.ApiHttp('/archive/param/archive/upload?ulType=DefaultUpload',formData,'post',true).then(backRes=>{
								if(backRes&&backRes.length>0){
									let imgData={
										fileId: backRes[0].fileId,
										contentType: backRes[0].contentType,
										fileType: backRes[0].contentType,
									}
									resolve(imgData);
								}
							}).catch(Error => {
								reject(Error);
							})
							
						})
					}
				});
				
				
			},
			downloadImg(){
				// 将画布内容转为base64编码
				let imgBase64 = canvas.toDataURL('png')
				canvas.toBlob(blob => {
					const date = new Date().getTime()
					const link = document.createElement('a')
					link.download = `${date}.png`
					link.href = URL.createObjectURL(blob)
					link.click()
					link.remove()
				})
				
				
				
			},
			// 浏览器分辨率改变 自适应重置
			resetSize() {
				const {
					width,
					height,
					left,
					top
				} = $('.drawing-board').getBoundingClientRect()
				const dx = Math.abs(left - client.offsetX)
				const dy = Math.abs(top - client.offsetY)
				// 浏览器分辨率改变 重新设置配置项
				config.width = width
				config.height = height
				client.offsetX = left
				client.offsetY = top
				// 重置canvas宽高 会导致 之前canvas的内容丢失，得先存一份，重置完宽高，再绘制上去
				const canvasData = ctx.getImageData(0, 0, canvas.width, canvas.height)
				// 重置 canvas 宽高
				canvas.width = width
				canvas.height = height
				// 改变完宽高 重绘画布
				ctx.putImageData(canvasData, dx, dy)
			}
		}
	}
</script>
<style scoped lang="scss">
	.sign-content{
		width: 100%;
		height: 100%;
		position: relative;
	}
	.drawing-board {
		width: 100%;
		height: 100%;
	}
	.canvas{
		border: 1px solid rgb(220, 223, 230);
		border-radius: 3px;
	}
	.tool-bar {
		position: absolute;
	    right: -60px;
	    bottom: -2px;
	    line-height: initial;
	    .el-button{
	    	padding: 0;
	    }
	}
</style>